import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSearchContext } from '../../components/Layout';
import { UserDetailsSection } from '../../features/UserDetailsSection/UserDetailsSection';

export const AUTHOR_ID_PARAM = 'authorId';

export const UserDetails: React.VFC = () => {
    const { searchInput } = useSearchContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchInput === '') {
            navigate('/');
        }
    }, [searchInput]);

    return <UserDetailsSection searchInput={searchInput} />;
};
