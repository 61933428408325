import * as React from 'react';

import Masthead, { MastheadTitle, MastheadMenuButton, MastheadLink } from '@amzn/meridian/masthead';

interface Props {
    isSideBarOpen: boolean;
    setIsSideBarOpen: (isOpen: boolean) => void;
    title?: string;
    StageSelectComponent?: React.ReactNode;
    HeaderContentComponent?: React.ReactNode;
}

export const Header: React.FC<Props> = (props) => {
    const { isSideBarOpen, setIsSideBarOpen, title, StageSelectComponent, HeaderContentComponent } = props;

    const handleMenuButtonClick = () => {
        setIsSideBarOpen(!isSideBarOpen);
    };

    return (
        <Masthead>
            <MastheadMenuButton onClick={handleMenuButtonClick} />
            <MastheadTitle href='/'>{title}</MastheadTitle>
            <MastheadLink>{StageSelectComponent && StageSelectComponent}</MastheadLink>
            {HeaderContentComponent && HeaderContentComponent}
        </Masthead>
    );
};
