import { AxiosError } from 'axios';
import React, { useState } from 'react';

import { ListIdDeobfuscateResponse, ListIdObfuscateResponse } from '../../../listoramaAdmin-api/generated-src';
import ListORamaAdminApiFactory from '../../../listoramaAdmin-api/ListORamaAdminApiFactory';
import { validateListConst } from '../../../utils/validation';
import { ListIdObfuscationToolView } from './ListIdObfuscationToolView';

export interface ListIdObfuscationToolInput {
    listId: string;
}

export interface ListIdObfuscationToolResponseData {
    data?: ListIdDeobfuscateResponse & ListIdObfuscateResponse;
    fetching?: boolean;
    error?: AxiosError;
}

export const ListIdObfuscationTool: React.VFC = () => {
    const [inputData, setInputData] = useState<ListIdObfuscationToolInput>({ listId: '' });
    const [responseData, setResponseData] = useState<ListIdObfuscationToolResponseData>({ fetching: false });
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const validateInput = (): boolean => {
        if (inputData.listId.length === 0 || !validateListConst(inputData.listId)) {
            setResponseData({ error: new Error('Please enter a valid list ID.') as AxiosError });
            return false;
        } else {
            return true;
        }
    };

    const listIdObfuscate = async () => {
        setResponseData({ data: undefined, fetching: true });
        try {
            const deobfuscateResponse = await ListORamaAdminApiFactory().listIdDeobfuscate.idDeobfuscate({
                obfuscatedListId: inputData.listId
            });
            const obfuscateResponse = await ListORamaAdminApiFactory().listIdObfuscate.idObfuscate({
                deobfuscatedListId: inputData.listId
            });
            setResponseData({
                data: {
                    deobfuscatedListId: deobfuscateResponse.data.deobfuscatedListId,
                    obfuscatedListId: obfuscateResponse.data.obfuscatedListId
                },
                fetching: false
            });
        } catch (e) {
            setResponseData({ error: e as AxiosError, fetching: false });
        }
    };

    const onSubmit = () => {
        setResponseData({ error: undefined });
        if (validateInput()) {
            listIdObfuscate();
        }
    };

    const onInputChange = (newData: object) => {
        setInputData({ ...inputData, ...newData });
    };

    return (
        <ListIdObfuscationToolView
            inputData={inputData}
            onInputChange={onInputChange}
            responseData={responseData}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onSubmit={onSubmit}
        />
    );
};
