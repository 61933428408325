import { ListDetails, ListState } from '../listoramaAdmin-api/generated-src';

const LIST_CONST_REGEX = new RegExp(`ls[0-9]{7,19}`);
const T_CONST_REGEX = new RegExp(`tt[0-9]{7,19}`);
const N_CONST_REGEX = new RegExp(`nm[0-9]{7,19}`);
const VIDEO_CONST_REGEX = new RegExp(`vi[0-9]{7,19}`);
const IMAGE_CONST_REGEX = new RegExp(`rm[0-9]{7,10}$`);
const U_CONST_REGEX = new RegExp(`ur[0-9]{7,19}`);
const CUSTOMER_ID_REGEX = new RegExp(`^A[A-Z0-9]{1,63}$`);

export const validateListConst = (lconst: string): boolean => LIST_CONST_REGEX.test(lconst);
export const validateTConst = (liconst: string): boolean => T_CONST_REGEX.test(liconst);
export const validateNConst = (liconst: string): boolean => N_CONST_REGEX.test(liconst);
export const validateVideoConst = (liconst: string): boolean => VIDEO_CONST_REGEX.test(liconst);
export const validateImageConst = (liconst: string): boolean => IMAGE_CONST_REGEX.test(liconst);
export const validateUconst = (uconst: string): boolean => U_CONST_REGEX.test(uconst);
export const validateCustomerId = (customerId: string): boolean => CUSTOMER_ID_REGEX.test(customerId);

export const isListVisibleToPublic = (list: ListDetails): boolean => list.publik && list.state === ListState.Ok;
