import * as React from 'react';

import { DetailsTableCell } from '@amzn/imdb-shared-meridian-components/components/DetailsTable/DetailsTableCell';
import { CustomSingleCellDetailsTableRow } from '@amzn/imdb-shared-meridian-components/components/DetailsTable/DetailsTableRow';
import { NewWindowLink } from '@amzn/imdb-shared-meridian-components/components/link';
import Table from '@amzn/meridian/table';

import { ListDetails } from '../../../../../listoramaAdmin-api/generated-src/api';
import { getListWebsiteUrl } from '../../../../../utils/url';
import { isListVisibleToPublic } from '../../../../../utils/validation';

type ListMainDetailsCellId =
    | 'Name'
    | 'Description'
    | 'State'
    | 'Visibility'
    | 'URL'
    | 'Flags'
    | 'Created'
    | 'Last Modified';

export interface ListMainDetailsTableProps {
    list: ListDetails;
}

export const ListMainDetailsTable: React.FC<ListMainDetailsTableProps> = (props: ListMainDetailsTableProps) => {
    const { list } = props;
    const listMainDetailsTableRows = createTableRows(list);

    return (
        <Table
            data-test-id='listMainDetailsTable'
            showDividers={true}
            spacing='small'
            rowComponents={[CustomSingleCellDetailsTableRow]}
        >
            {listMainDetailsTableRows.map((row) => (
                <CustomSingleCellDetailsTableRow
                    key={row.name}
                    header={row.name}
                    data={row.data}
                    getTableCell={getListMainDetailsTableCell}
                />
            ))}
        </Table>
    );
};

interface CustomTableRowProps<T extends string> {
    name: T;
    data: string;
}

const getListMainDetailsTableCell = (cellId: ListMainDetailsCellId, data: string) => {
    switch (cellId) {
        case 'URL':
            return data ? (
                <DetailsTableCell
                    data-test-id='listLink'
                    CustomDataElement={<NewWindowLink url={data} text={data} />}
                />
            ) : (
                <DetailsTableCell
                    data-test-id='listLink'
                    data='List not viewable on website (Private or not in OK state)'
                />
            );
        default:
            return <DetailsTableCell data={data} />;
    }
};

const createTableRows = (list: ListDetails): CustomTableRowProps<ListMainDetailsCellId>[] => [
    {
        name: 'Name',
        data: list.name
    },
    {
        name: 'Description',
        data: list.originalDescription ? list.originalDescription : 'No description'
    },
    {
        name: 'State',
        data: list.state
    },
    {
        name: 'Visibility',
        data: list.publik ? 'Public' : 'Private'
    },
    {
        name: 'URL',
        data: isListVisibleToPublic(list) ? getListWebsiteUrl(list.listId) : ''
    },
    {
        name: 'Flags',
        data: list.flags.length > 0 ? list.flags.join(', ') : 'No flags'
    },
    {
        name: 'Created',
        data: list.created
    },
    {
        name: 'Last Modified',
        data: list.modified
    }
];
