/* tslint:disable */
/* eslint-disable */
/**
 * ListORamaAdminAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdminAddListFlagRequest
 */
export interface AdminAddListFlagRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAddListFlagRequest
     */
    'listId': string;
    /**
     * 
     * @type {ListFlag}
     * @memberof AdminAddListFlagRequest
     */
    'listFlag': ListFlag;
}
/**
 * 
 * @export
 * @interface AdminAddListFlagResponse
 */
export interface AdminAddListFlagResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AdminAddListFlagResponse
     */
    'isAddListFlagSuccess': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminAddListFlagResponse
     */
    'listId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminAddListFlagResponse
     */
    'listFlags': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminBatchAddListFlagRequest
 */
export interface AdminBatchAddListFlagRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminBatchAddListFlagRequest
     */
    'listIds': Array<string>;
    /**
     * 
     * @type {ListFlag}
     * @memberof AdminBatchAddListFlagRequest
     */
    'listFlag': ListFlag;
}
/**
 * 
 * @export
 * @interface AdminBatchAddListFlagResponse
 */
export interface AdminBatchAddListFlagResponse {
    /**
     * 
     * @type {Array<AdminAddListFlagResponse>}
     * @memberof AdminBatchAddListFlagResponse
     */
    'allRecords': Array<AdminAddListFlagResponse>;
    /**
     * 
     * @type {Array<ListError>}
     * @memberof AdminBatchAddListFlagResponse
     */
    'errors': Array<ListError>;
}
/**
 * 
 * @export
 * @interface AdminBatchRemoveListFlagRequest
 */
export interface AdminBatchRemoveListFlagRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminBatchRemoveListFlagRequest
     */
    'listIds': Array<string>;
    /**
     * 
     * @type {ListFlag}
     * @memberof AdminBatchRemoveListFlagRequest
     */
    'listFlag': ListFlag;
}
/**
 * 
 * @export
 * @interface AdminBatchRemoveListFlagResponse
 */
export interface AdminBatchRemoveListFlagResponse {
    /**
     * 
     * @type {Array<AdminRemoveListFlagResponse>}
     * @memberof AdminBatchRemoveListFlagResponse
     */
    'allRecords': Array<AdminRemoveListFlagResponse>;
    /**
     * 
     * @type {Array<ListError>}
     * @memberof AdminBatchRemoveListFlagResponse
     */
    'errors': Array<ListError>;
}
/**
 * 
 * @export
 * @interface AdminEditListStateBatchRequest
 */
export interface AdminEditListStateBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminEditListStateBatchRequest
     */
    'customerId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminEditListStateBatchRequest
     */
    'listIds': Array<string>;
    /**
     * 
     * @type {ListState}
     * @memberof AdminEditListStateBatchRequest
     */
    'state': ListState;
}
/**
 * 
 * @export
 * @interface AdminEditListStateBatchResponse
 */
export interface AdminEditListStateBatchResponse {
    /**
     * 
     * @type {Array<EditListStateResponse>}
     * @memberof AdminEditListStateBatchResponse
     */
    'allRecords': Array<EditListStateResponse>;
    /**
     * 
     * @type {Array<ListError>}
     * @memberof AdminEditListStateBatchResponse
     */
    'errors': Array<ListError>;
}
/**
 * 
 * @export
 * @interface AdminEditListStateRequest
 */
export interface AdminEditListStateRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminEditListStateRequest
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminEditListStateRequest
     */
    'listId': string;
    /**
     * 
     * @type {ListState}
     * @memberof AdminEditListStateRequest
     */
    'state': ListState;
}
/**
 * 
 * @export
 * @interface AdminEditListStateResponse
 */
export interface AdminEditListStateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AdminEditListStateResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface AdminRemoveListFlagRequest
 */
export interface AdminRemoveListFlagRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRemoveListFlagRequest
     */
    'listId': string;
    /**
     * 
     * @type {ListFlag}
     * @memberof AdminRemoveListFlagRequest
     */
    'listFlag': ListFlag;
}
/**
 * 
 * @export
 * @interface AdminRemoveListFlagResponse
 */
export interface AdminRemoveListFlagResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AdminRemoveListFlagResponse
     */
    'isRemoveListFlagSuccess': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminRemoveListFlagResponse
     */
    'listId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminRemoveListFlagResponse
     */
    'listFlags': Array<string>;
}
/**
 * 
 * @export
 * @interface EditListStateResponse
 */
export interface EditListStateResponse {
    /**
     * 
     * @type {string}
     * @memberof EditListStateResponse
     */
    'listId': string;
    /**
     * 
     * @type {ListState}
     * @memberof EditListStateResponse
     */
    'state': ListState;
}
/**
 * 
 * @export
 * @interface ListAuthorDetails
 */
export interface ListAuthorDetails {
    /**
     * 
     * @type {string}
     * @memberof ListAuthorDetails
     */
    'authorId': string;
    /**
     * 
     * @type {string}
     * @memberof ListAuthorDetails
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof ListAuthorDetails
     */
    'registrationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ListAuthorDetails
     */
    'status': string;
    /**
     * 
     * @type {boolean}
     * @memberof ListAuthorDetails
     */
    'isPro': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListAuthorDetails
     */
    'isShadowbanned': boolean;
}
/**
 * 
 * @export
 * @interface ListDetails
 */
export interface ListDetails {
    /**
     * 
     * @type {string}
     * @memberof ListDetails
     */
    'listId': string;
    /**
     * 
     * @type {string}
     * @memberof ListDetails
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ListDetails
     */
    'size': number;
    /**
     * 
     * @type {Array<ListItemDetails>}
     * @memberof ListDetails
     */
    'items': Array<ListItemDetails>;
    /**
     * 
     * @type {string}
     * @memberof ListDetails
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ListDetails
     */
    'modified': string;
    /**
     * 
     * @type {ListType}
     * @memberof ListDetails
     */
    'listType': ListType;
    /**
     * 
     * @type {string}
     * @memberof ListDetails
     */
    'listClass': string;
    /**
     * 
     * @type {boolean}
     * @memberof ListDetails
     */
    'publik': boolean;
    /**
     * 
     * @type {string}
     * @memberof ListDetails
     */
    'originalDescription': string;
    /**
     * 
     * @type {Array<ListFlag>}
     * @memberof ListDetails
     */
    'flags': Array<ListFlag>;
    /**
     * 
     * @type {ListState}
     * @memberof ListDetails
     */
    'state': ListState;
    /**
     * 
     * @type {string}
     * @memberof ListDetails
     */
    'locale': string;
    /**
     * 
     * @type {boolean}
     * @memberof ListDetails
     */
    'allowDuplicates': boolean;
    /**
     * 
     * @type {{ [key: string]: MetadataValue; }}
     * @memberof ListDetails
     */
    'metadata': { [key: string]: MetadataValue; };
}
/**
 * 
 * @export
 * @interface ListDetailsResponse
 */
export interface ListDetailsResponse {
    /**
     * 
     * @type {ListAuthorDetails}
     * @memberof ListDetailsResponse
     */
    'author': ListAuthorDetails;
    /**
     * 
     * @type {ListDetails}
     * @memberof ListDetailsResponse
     */
    'list': ListDetails;
}
/**
 * 
 * @export
 * @interface ListError
 */
export interface ListError {
    /**
     * 
     * @type {string}
     * @memberof ListError
     */
    'listId': string;
    /**
     * 
     * @type {number}
     * @memberof ListError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ListError
     */
    'message': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ListFlag {
    Spam = 'SPAM',
    Obscene = 'OBSCENE',
    Porn = 'PORN'
}

/**
 * 
 * @export
 * @interface ListIdDeobfuscateRequest
 */
export interface ListIdDeobfuscateRequest {
    /**
     * 
     * @type {string}
     * @memberof ListIdDeobfuscateRequest
     */
    'obfuscatedListId': string;
}
/**
 * 
 * @export
 * @interface ListIdDeobfuscateResponse
 */
export interface ListIdDeobfuscateResponse {
    /**
     * 
     * @type {string}
     * @memberof ListIdDeobfuscateResponse
     */
    'deobfuscatedListId': string;
}
/**
 * 
 * @export
 * @interface ListIdObfuscateRequest
 */
export interface ListIdObfuscateRequest {
    /**
     * 
     * @type {string}
     * @memberof ListIdObfuscateRequest
     */
    'deobfuscatedListId': string;
}
/**
 * 
 * @export
 * @interface ListIdObfuscateResponse
 */
export interface ListIdObfuscateResponse {
    /**
     * 
     * @type {string}
     * @memberof ListIdObfuscateResponse
     */
    'obfuscatedListId': string;
}
/**
 * 
 * @export
 * @interface ListItemDetails
 */
export interface ListItemDetails {
    /**
     * 
     * @type {string}
     * @memberof ListItemDetails
     */
    'listId': string;
    /**
     * 
     * @type {string}
     * @memberof ListItemDetails
     */
    'listItemId': string;
    /**
     * 
     * @type {string}
     * @memberof ListItemDetails
     */
    'theConst': string;
    /**
     * 
     * @type {string}
     * @memberof ListItemDetails
     */
    'itemText'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListItemDetails
     */
    'originalDescription': string;
    /**
     * 
     * @type {string}
     * @memberof ListItemDetails
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ListItemDetails
     */
    'modified': string;
    /**
     * 
     * @type {number}
     * @memberof ListItemDetails
     */
    'position': number;
    /**
     * 
     * @type {{ [key: string]: MetadataValue; }}
     * @memberof ListItemDetails
     */
    'metadata': { [key: string]: MetadataValue; };
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ListState {
    Ok = 'OK',
    Restricted = 'RESTRICTED',
    Inprogress = 'INPROGRESS',
    Tombstone = 'TOMBSTONE'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ListType {
    Titles = 'TITLES',
    People = 'PEOPLE',
    Characters = 'CHARACTERS',
    Lists = 'LISTS',
    Images = 'IMAGES',
    Theaters = 'THEATERS',
    Videos = 'VIDEOS',
    Galleries = 'GALLERIES',
    Users = 'USERS'
}

/**
 * 
 * @export
 * @interface MetadataValue
 */
export interface MetadataValue {
    /**
     * 
     * @type {string}
     * @memberof MetadataValue
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataValue
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataValue
     */
    'modified': string;
    /**
     * 
     * @type {number}
     * @memberof MetadataValue
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface PingResponse
 */
export interface PingResponse {
    /**
     * 
     * @type {string}
     * @memberof PingResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface PredefinedListDetails
 */
export interface PredefinedListDetails {
    /**
     * 
     * @type {string}
     * @memberof PredefinedListDetails
     */
    'listId': string;
    /**
     * 
     * @type {ListType}
     * @memberof PredefinedListDetails
     */
    'listType': ListType;
    /**
     * 
     * @type {string}
     * @memberof PredefinedListDetails
     */
    'listClass': string;
    /**
     * 
     * @type {boolean}
     * @memberof PredefinedListDetails
     */
    'publik': boolean;
    /**
     * 
     * @type {ListState}
     * @memberof PredefinedListDetails
     */
    'state': ListState;
    /**
     * 
     * @type {number}
     * @memberof PredefinedListDetails
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof PredefinedListDetails
     */
    'modified': string;
    /**
     * 
     * @type {string}
     * @memberof PredefinedListDetails
     */
    'created': string;
}
/**
 * 
 * @export
 * @interface UserDetailsResponse
 */
export interface UserDetailsResponse {
    /**
     * 
     * @type {UserListsDetails}
     * @memberof UserDetailsResponse
     */
    'user': UserListsDetails;
}
/**
 * 
 * @export
 * @interface UserListsDetails
 */
export interface UserListsDetails {
    /**
     * 
     * @type {ListAuthorDetails}
     * @memberof UserListsDetails
     */
    'author': ListAuthorDetails;
    /**
     * 
     * @type {Array<ListDetails>}
     * @memberof UserListsDetails
     */
    'lists': Array<ListDetails>;
    /**
     * 
     * @type {Array<PredefinedListDetails>}
     * @memberof UserListsDetails
     */
    'predefinedLists': Array<PredefinedListDetails>;
}

/**
 * AdminAddListFlagApi - axios parameter creator
 * @export
 */
export const AdminAddListFlagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a flag to a list
         * @param {AdminAddListFlagRequest} adminAddListFlagRequest List, ListFlag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddListFlag: async (adminAddListFlagRequest: AdminAddListFlagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminAddListFlagRequest' is not null or undefined
            assertParamExists('adminAddListFlag', 'adminAddListFlagRequest', adminAddListFlagRequest)
            const localVarPath = `/list/adminAddListFlag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAddListFlagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAddListFlagApi - functional programming interface
 * @export
 */
export const AdminAddListFlagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAddListFlagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a flag to a list
         * @param {AdminAddListFlagRequest} adminAddListFlagRequest List, ListFlag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddListFlag(adminAddListFlagRequest: AdminAddListFlagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAddListFlagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddListFlag(adminAddListFlagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAddListFlagApi - factory interface
 * @export
 */
export const AdminAddListFlagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAddListFlagApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a flag to a list
         * @param {AdminAddListFlagRequest} adminAddListFlagRequest List, ListFlag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddListFlag(adminAddListFlagRequest: AdminAddListFlagRequest, options?: any): AxiosPromise<AdminAddListFlagResponse> {
            return localVarFp.adminAddListFlag(adminAddListFlagRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAddListFlagApi - object-oriented interface
 * @export
 * @class AdminAddListFlagApi
 * @extends {BaseAPI}
 */
export class AdminAddListFlagApi extends BaseAPI {
    /**
     * 
     * @summary Add a flag to a list
     * @param {AdminAddListFlagRequest} adminAddListFlagRequest List, ListFlag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAddListFlagApi
     */
    public adminAddListFlag(adminAddListFlagRequest: AdminAddListFlagRequest, options?: AxiosRequestConfig) {
        return AdminAddListFlagApiFp(this.configuration).adminAddListFlag(adminAddListFlagRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminBatchAddListFlagApi - axios parameter creator
 * @export
 */
export const AdminBatchAddListFlagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Batch Add flag to multiple lists
         * @param {AdminBatchAddListFlagRequest} adminBatchAddListFlagRequest ListIds, ListFlag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBatchAddListFlag: async (adminBatchAddListFlagRequest: AdminBatchAddListFlagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminBatchAddListFlagRequest' is not null or undefined
            assertParamExists('adminBatchAddListFlag', 'adminBatchAddListFlagRequest', adminBatchAddListFlagRequest)
            const localVarPath = `/list/adminBatchAddListFlag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminBatchAddListFlagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminBatchAddListFlagApi - functional programming interface
 * @export
 */
export const AdminBatchAddListFlagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminBatchAddListFlagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Batch Add flag to multiple lists
         * @param {AdminBatchAddListFlagRequest} adminBatchAddListFlagRequest ListIds, ListFlag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBatchAddListFlag(adminBatchAddListFlagRequest: AdminBatchAddListFlagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminBatchAddListFlagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBatchAddListFlag(adminBatchAddListFlagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminBatchAddListFlagApi - factory interface
 * @export
 */
export const AdminBatchAddListFlagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminBatchAddListFlagApiFp(configuration)
    return {
        /**
         * 
         * @summary Batch Add flag to multiple lists
         * @param {AdminBatchAddListFlagRequest} adminBatchAddListFlagRequest ListIds, ListFlag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBatchAddListFlag(adminBatchAddListFlagRequest: AdminBatchAddListFlagRequest, options?: any): AxiosPromise<AdminBatchAddListFlagResponse> {
            return localVarFp.adminBatchAddListFlag(adminBatchAddListFlagRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminBatchAddListFlagApi - object-oriented interface
 * @export
 * @class AdminBatchAddListFlagApi
 * @extends {BaseAPI}
 */
export class AdminBatchAddListFlagApi extends BaseAPI {
    /**
     * 
     * @summary Batch Add flag to multiple lists
     * @param {AdminBatchAddListFlagRequest} adminBatchAddListFlagRequest ListIds, ListFlag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBatchAddListFlagApi
     */
    public adminBatchAddListFlag(adminBatchAddListFlagRequest: AdminBatchAddListFlagRequest, options?: AxiosRequestConfig) {
        return AdminBatchAddListFlagApiFp(this.configuration).adminBatchAddListFlag(adminBatchAddListFlagRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminBatchRemoveListFlagApi - axios parameter creator
 * @export
 */
export const AdminBatchRemoveListFlagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Batch Remove flag to multiple lists
         * @param {AdminBatchRemoveListFlagRequest} adminBatchRemoveListFlagRequest ListIds, ListFlag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBatchRemoveListFlag: async (adminBatchRemoveListFlagRequest: AdminBatchRemoveListFlagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminBatchRemoveListFlagRequest' is not null or undefined
            assertParamExists('adminBatchRemoveListFlag', 'adminBatchRemoveListFlagRequest', adminBatchRemoveListFlagRequest)
            const localVarPath = `/list/adminBatchRemoveListFlag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminBatchRemoveListFlagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminBatchRemoveListFlagApi - functional programming interface
 * @export
 */
export const AdminBatchRemoveListFlagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminBatchRemoveListFlagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Batch Remove flag to multiple lists
         * @param {AdminBatchRemoveListFlagRequest} adminBatchRemoveListFlagRequest ListIds, ListFlag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBatchRemoveListFlag(adminBatchRemoveListFlagRequest: AdminBatchRemoveListFlagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminBatchRemoveListFlagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBatchRemoveListFlag(adminBatchRemoveListFlagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminBatchRemoveListFlagApi - factory interface
 * @export
 */
export const AdminBatchRemoveListFlagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminBatchRemoveListFlagApiFp(configuration)
    return {
        /**
         * 
         * @summary Batch Remove flag to multiple lists
         * @param {AdminBatchRemoveListFlagRequest} adminBatchRemoveListFlagRequest ListIds, ListFlag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBatchRemoveListFlag(adminBatchRemoveListFlagRequest: AdminBatchRemoveListFlagRequest, options?: any): AxiosPromise<AdminBatchRemoveListFlagResponse> {
            return localVarFp.adminBatchRemoveListFlag(adminBatchRemoveListFlagRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminBatchRemoveListFlagApi - object-oriented interface
 * @export
 * @class AdminBatchRemoveListFlagApi
 * @extends {BaseAPI}
 */
export class AdminBatchRemoveListFlagApi extends BaseAPI {
    /**
     * 
     * @summary Batch Remove flag to multiple lists
     * @param {AdminBatchRemoveListFlagRequest} adminBatchRemoveListFlagRequest ListIds, ListFlag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBatchRemoveListFlagApi
     */
    public adminBatchRemoveListFlag(adminBatchRemoveListFlagRequest: AdminBatchRemoveListFlagRequest, options?: AxiosRequestConfig) {
        return AdminBatchRemoveListFlagApiFp(this.configuration).adminBatchRemoveListFlag(adminBatchRemoveListFlagRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminEditListStateApi - axios parameter creator
 * @export
 */
export const AdminEditListStateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change a list\'s state to OK, RESTRICTED, INPROGRESS, TOMBSTONE
         * @param {AdminEditListStateRequest} adminEditListStateRequest User, List, ListState
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditListState: async (adminEditListStateRequest: AdminEditListStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminEditListStateRequest' is not null or undefined
            assertParamExists('adminEditListState', 'adminEditListStateRequest', adminEditListStateRequest)
            const localVarPath = `/list/adminEditListState`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEditListStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminEditListStateApi - functional programming interface
 * @export
 */
export const AdminEditListStateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminEditListStateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change a list\'s state to OK, RESTRICTED, INPROGRESS, TOMBSTONE
         * @param {AdminEditListStateRequest} adminEditListStateRequest User, List, ListState
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditListState(adminEditListStateRequest: AdminEditListStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEditListStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditListState(adminEditListStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminEditListStateApi - factory interface
 * @export
 */
export const AdminEditListStateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminEditListStateApiFp(configuration)
    return {
        /**
         * 
         * @summary Change a list\'s state to OK, RESTRICTED, INPROGRESS, TOMBSTONE
         * @param {AdminEditListStateRequest} adminEditListStateRequest User, List, ListState
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditListState(adminEditListStateRequest: AdminEditListStateRequest, options?: any): AxiosPromise<AdminEditListStateResponse> {
            return localVarFp.adminEditListState(adminEditListStateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminEditListStateApi - object-oriented interface
 * @export
 * @class AdminEditListStateApi
 * @extends {BaseAPI}
 */
export class AdminEditListStateApi extends BaseAPI {
    /**
     * 
     * @summary Change a list\'s state to OK, RESTRICTED, INPROGRESS, TOMBSTONE
     * @param {AdminEditListStateRequest} adminEditListStateRequest User, List, ListState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEditListStateApi
     */
    public adminEditListState(adminEditListStateRequest: AdminEditListStateRequest, options?: AxiosRequestConfig) {
        return AdminEditListStateApiFp(this.configuration).adminEditListState(adminEditListStateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminEditListStateBatchApi - axios parameter creator
 * @export
 */
export const AdminEditListStateBatchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change the list state for multiple lists to OK, RESTRICTED, INPROGRESS, TOMBSTONE
         * @param {AdminEditListStateBatchRequest} adminEditListStateBatchRequest User, ListIds, ListState
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditListStateBatch: async (adminEditListStateBatchRequest: AdminEditListStateBatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminEditListStateBatchRequest' is not null or undefined
            assertParamExists('adminEditListStateBatch', 'adminEditListStateBatchRequest', adminEditListStateBatchRequest)
            const localVarPath = `/list/adminEditListStateBatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEditListStateBatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminEditListStateBatchApi - functional programming interface
 * @export
 */
export const AdminEditListStateBatchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminEditListStateBatchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change the list state for multiple lists to OK, RESTRICTED, INPROGRESS, TOMBSTONE
         * @param {AdminEditListStateBatchRequest} adminEditListStateBatchRequest User, ListIds, ListState
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEditListStateBatch(adminEditListStateBatchRequest: AdminEditListStateBatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEditListStateBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEditListStateBatch(adminEditListStateBatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminEditListStateBatchApi - factory interface
 * @export
 */
export const AdminEditListStateBatchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminEditListStateBatchApiFp(configuration)
    return {
        /**
         * 
         * @summary Change the list state for multiple lists to OK, RESTRICTED, INPROGRESS, TOMBSTONE
         * @param {AdminEditListStateBatchRequest} adminEditListStateBatchRequest User, ListIds, ListState
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditListStateBatch(adminEditListStateBatchRequest: AdminEditListStateBatchRequest, options?: any): AxiosPromise<AdminEditListStateBatchResponse> {
            return localVarFp.adminEditListStateBatch(adminEditListStateBatchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminEditListStateBatchApi - object-oriented interface
 * @export
 * @class AdminEditListStateBatchApi
 * @extends {BaseAPI}
 */
export class AdminEditListStateBatchApi extends BaseAPI {
    /**
     * 
     * @summary Change the list state for multiple lists to OK, RESTRICTED, INPROGRESS, TOMBSTONE
     * @param {AdminEditListStateBatchRequest} adminEditListStateBatchRequest User, ListIds, ListState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEditListStateBatchApi
     */
    public adminEditListStateBatch(adminEditListStateBatchRequest: AdminEditListStateBatchRequest, options?: AxiosRequestConfig) {
        return AdminEditListStateBatchApiFp(this.configuration).adminEditListStateBatch(adminEditListStateBatchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminRemoveListFlagApi - axios parameter creator
 * @export
 */
export const AdminRemoveListFlagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Remove a flag from a list
         * @param {AdminRemoveListFlagRequest} adminRemoveListFlagRequest List, ListFlag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveListFlag: async (adminRemoveListFlagRequest: AdminRemoveListFlagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminRemoveListFlagRequest' is not null or undefined
            assertParamExists('adminRemoveListFlag', 'adminRemoveListFlagRequest', adminRemoveListFlagRequest)
            const localVarPath = `/list/adminRemoveListFlag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminRemoveListFlagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminRemoveListFlagApi - functional programming interface
 * @export
 */
export const AdminRemoveListFlagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminRemoveListFlagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Remove a flag from a list
         * @param {AdminRemoveListFlagRequest} adminRemoveListFlagRequest List, ListFlag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRemoveListFlag(adminRemoveListFlagRequest: AdminRemoveListFlagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminRemoveListFlagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRemoveListFlag(adminRemoveListFlagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminRemoveListFlagApi - factory interface
 * @export
 */
export const AdminRemoveListFlagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminRemoveListFlagApiFp(configuration)
    return {
        /**
         * 
         * @summary Remove a flag from a list
         * @param {AdminRemoveListFlagRequest} adminRemoveListFlagRequest List, ListFlag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemoveListFlag(adminRemoveListFlagRequest: AdminRemoveListFlagRequest, options?: any): AxiosPromise<AdminRemoveListFlagResponse> {
            return localVarFp.adminRemoveListFlag(adminRemoveListFlagRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminRemoveListFlagApi - object-oriented interface
 * @export
 * @class AdminRemoveListFlagApi
 * @extends {BaseAPI}
 */
export class AdminRemoveListFlagApi extends BaseAPI {
    /**
     * 
     * @summary Remove a flag from a list
     * @param {AdminRemoveListFlagRequest} adminRemoveListFlagRequest List, ListFlag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRemoveListFlagApi
     */
    public adminRemoveListFlag(adminRemoveListFlagRequest: AdminRemoveListFlagRequest, options?: AxiosRequestConfig) {
        return AdminRemoveListFlagApiFp(this.configuration).adminRemoveListFlag(adminRemoveListFlagRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdDeobfuscateApi - axios parameter creator
 * @export
 */
export const IdDeobfuscateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deobfuscate an obfuscated list ID
         * @param {ListIdDeobfuscateRequest} listIdDeobfuscateRequest obfuscatedListId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idDeobfuscate: async (listIdDeobfuscateRequest: ListIdDeobfuscateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listIdDeobfuscateRequest' is not null or undefined
            assertParamExists('idDeobfuscate', 'listIdDeobfuscateRequest', listIdDeobfuscateRequest)
            const localVarPath = `/list/idDeobfuscate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listIdDeobfuscateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdDeobfuscateApi - functional programming interface
 * @export
 */
export const IdDeobfuscateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdDeobfuscateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deobfuscate an obfuscated list ID
         * @param {ListIdDeobfuscateRequest} listIdDeobfuscateRequest obfuscatedListId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idDeobfuscate(listIdDeobfuscateRequest: ListIdDeobfuscateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListIdDeobfuscateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idDeobfuscate(listIdDeobfuscateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdDeobfuscateApi - factory interface
 * @export
 */
export const IdDeobfuscateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdDeobfuscateApiFp(configuration)
    return {
        /**
         * 
         * @summary Deobfuscate an obfuscated list ID
         * @param {ListIdDeobfuscateRequest} listIdDeobfuscateRequest obfuscatedListId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idDeobfuscate(listIdDeobfuscateRequest: ListIdDeobfuscateRequest, options?: any): AxiosPromise<ListIdDeobfuscateResponse> {
            return localVarFp.idDeobfuscate(listIdDeobfuscateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdDeobfuscateApi - object-oriented interface
 * @export
 * @class IdDeobfuscateApi
 * @extends {BaseAPI}
 */
export class IdDeobfuscateApi extends BaseAPI {
    /**
     * 
     * @summary Deobfuscate an obfuscated list ID
     * @param {ListIdDeobfuscateRequest} listIdDeobfuscateRequest obfuscatedListId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdDeobfuscateApi
     */
    public idDeobfuscate(listIdDeobfuscateRequest: ListIdDeobfuscateRequest, options?: AxiosRequestConfig) {
        return IdDeobfuscateApiFp(this.configuration).idDeobfuscate(listIdDeobfuscateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdObfuscateApi - axios parameter creator
 * @export
 */
export const IdObfuscateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Obfuscate a deobfuscated list ID
         * @param {ListIdObfuscateRequest} listIdObfuscateRequest deobfuscatedListId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idObfuscate: async (listIdObfuscateRequest: ListIdObfuscateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listIdObfuscateRequest' is not null or undefined
            assertParamExists('idObfuscate', 'listIdObfuscateRequest', listIdObfuscateRequest)
            const localVarPath = `/list/idObfuscate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listIdObfuscateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdObfuscateApi - functional programming interface
 * @export
 */
export const IdObfuscateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdObfuscateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Obfuscate a deobfuscated list ID
         * @param {ListIdObfuscateRequest} listIdObfuscateRequest deobfuscatedListId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idObfuscate(listIdObfuscateRequest: ListIdObfuscateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListIdObfuscateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idObfuscate(listIdObfuscateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdObfuscateApi - factory interface
 * @export
 */
export const IdObfuscateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdObfuscateApiFp(configuration)
    return {
        /**
         * 
         * @summary Obfuscate a deobfuscated list ID
         * @param {ListIdObfuscateRequest} listIdObfuscateRequest deobfuscatedListId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idObfuscate(listIdObfuscateRequest: ListIdObfuscateRequest, options?: any): AxiosPromise<ListIdObfuscateResponse> {
            return localVarFp.idObfuscate(listIdObfuscateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdObfuscateApi - object-oriented interface
 * @export
 * @class IdObfuscateApi
 * @extends {BaseAPI}
 */
export class IdObfuscateApi extends BaseAPI {
    /**
     * 
     * @summary Obfuscate a deobfuscated list ID
     * @param {ListIdObfuscateRequest} listIdObfuscateRequest deobfuscatedListId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdObfuscateApi
     */
    public idObfuscate(listIdObfuscateRequest: ListIdObfuscateRequest, options?: AxiosRequestConfig) {
        return IdObfuscateApiFp(this.configuration).idObfuscate(listIdObfuscateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ListDetailsApi - axios parameter creator
 * @export
 */
export const ListDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get details about a list by list Id
         * @param {string} listId The unique identifier for a list.
         * @param {number} [limit] Optional argument for number of list items to be returned.
         * @param {number} [offset] Optional argument for offset of list items to be returned from the start of the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDetails: async (listId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listId' is not null or undefined
            assertParamExists('listDetails', 'listId', listId)
            const localVarPath = `/list/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (listId !== undefined) {
                localVarQueryParameter['listId'] = listId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListDetailsApi - functional programming interface
 * @export
 */
export const ListDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get details about a list by list Id
         * @param {string} listId The unique identifier for a list.
         * @param {number} [limit] Optional argument for number of list items to be returned.
         * @param {number} [offset] Optional argument for offset of list items to be returned from the start of the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDetails(listId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDetails(listId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ListDetailsApi - factory interface
 * @export
 */
export const ListDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListDetailsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get details about a list by list Id
         * @param {string} listId The unique identifier for a list.
         * @param {number} [limit] Optional argument for number of list items to be returned.
         * @param {number} [offset] Optional argument for offset of list items to be returned from the start of the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDetails(listId: string, limit?: number, offset?: number, options?: any): AxiosPromise<ListDetailsResponse> {
            return localVarFp.listDetails(listId, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListDetailsApi - object-oriented interface
 * @export
 * @class ListDetailsApi
 * @extends {BaseAPI}
 */
export class ListDetailsApi extends BaseAPI {
    /**
     * 
     * @summary Get details about a list by list Id
     * @param {string} listId The unique identifier for a list.
     * @param {number} [limit] Optional argument for number of list items to be returned.
     * @param {number} [offset] Optional argument for offset of list items to be returned from the start of the list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListDetailsApi
     */
    public listDetails(listId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ListDetailsApiFp(this.configuration).listDetails(listId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get ping to test service healthiness
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get ping to test service healthiness
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get ping to test service healthiness
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): AxiosPromise<PingResponse> {
            return localVarFp.ping(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @summary Get ping to test service healthiness
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public ping(options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).ping(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserDetailsApi - axios parameter creator
 * @export
 */
export const UserDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get details about a user and their lists
         * @param {string} customerId The unique identifier for a user.
         * @param {number} [limit] Optional argument for number of user\&#39;s lists to be returned.
         * @param {number} [offset] Optional argument for offset of user\&#39;s lists to be returned from the start of the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDetails: async (customerId: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('userDetails', 'customerId', customerId)
            const localVarPath = `/user/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserDetailsApi - functional programming interface
 * @export
 */
export const UserDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get details about a user and their lists
         * @param {string} customerId The unique identifier for a user.
         * @param {number} [limit] Optional argument for number of user\&#39;s lists to be returned.
         * @param {number} [offset] Optional argument for offset of user\&#39;s lists to be returned from the start of the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDetails(customerId: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDetails(customerId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserDetailsApi - factory interface
 * @export
 */
export const UserDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserDetailsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get details about a user and their lists
         * @param {string} customerId The unique identifier for a user.
         * @param {number} [limit] Optional argument for number of user\&#39;s lists to be returned.
         * @param {number} [offset] Optional argument for offset of user\&#39;s lists to be returned from the start of the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDetails(customerId: string, limit?: number, offset?: number, options?: any): AxiosPromise<UserDetailsResponse> {
            return localVarFp.userDetails(customerId, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserDetailsApi - object-oriented interface
 * @export
 * @class UserDetailsApi
 * @extends {BaseAPI}
 */
export class UserDetailsApi extends BaseAPI {
    /**
     * 
     * @summary Get details about a user and their lists
     * @param {string} customerId The unique identifier for a user.
     * @param {number} [limit] Optional argument for number of user\&#39;s lists to be returned.
     * @param {number} [offset] Optional argument for offset of user\&#39;s lists to be returned from the start of the list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDetailsApi
     */
    public userDetails(customerId: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return UserDetailsApiFp(this.configuration).userDetails(customerId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}


