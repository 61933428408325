import React from 'react';
import { useState } from 'react';

import {
    CustomRowTableCellProps,
    Data,
    DataTable,
    Header
} from '@amzn/imdb-shared-meridian-components/components/DataTable';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Pagination from '@amzn/meridian/pagination';
import Text from '@amzn/meridian/text';

import { ListItemDetails } from '../../../../../listoramaAdmin-api/generated-src/api';
import { ListItemsText, ItemText } from '../../queries/GetListItemsTextQuery';

export type ColumnId = 'position' | 'theConst' | 'itemText' | 'originalDescription' | 'modified';

interface ListItemsDataTableProps {
    listItems: ListItemDetails[];
    listItemsText: ListItemsText;
}

const ITEMS_PER_PAGE = 15;

export const ListItemsDataTable: React.FC<ListItemsDataTableProps> = (props: ListItemsDataTableProps) => {
    const { listItems, listItemsText } = props;

    const [currentPage, setCurrentPage] = useState(1);
    const firstVisibleIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const lastVisibleIndex = firstVisibleIndex + ITEMS_PER_PAGE;
    const numberOfPages = Math.ceil(listItems.length / ITEMS_PER_PAGE);

    return listItems.length > 0 ? (
        <Column width='100%' data-test-id='listItemsTable'>
            <Heading level={5}>{`List Size: ${listItems.length}`}</Heading>
            <DataTable
                data={createTablesData(listItems.slice(firstVisibleIndex, lastVisibleIndex), listItemsText)}
                CustomRowTableCell={CustomRowTableCell}
            />
            <Pagination
                showSkipArrows={true}
                numberOfPages={numberOfPages}
                onChange={setCurrentPage}
                currentPage={currentPage}
            />
        </Column>
    ) : (
        <Text type='h300'>List is empty.</Text>
    );
};

const CustomRowTableCell: React.VFC<CustomRowTableCellProps<ColumnId>> = (props) => {
    const { row, headerId } = props;
    switch (headerId) {
        case 'originalDescription':
            return (
                <DefaultTableCellContent
                    data={row.originalDescription ? row.originalDescription.toString().substring(0, 100) : ''}
                />
            );
        default:
            return <DefaultTableCellContent data={row[headerId]} />;
    }
};

const DefaultTableCellContent = ({ data }) => <>{data}</>;

const createTablesData = (listItems: ListItemDetails[], listItemsText: ListItemsText): Data<ColumnId> => {
    return {
        headers: createHeaders(),
        rows: listItems.map((li) => addListItemText(li, listItemsText as ItemText[]))
    };
};

const addListItemText = (li: ListItemDetails, listItemsText: ItemText[]): ListItemDetails => {
    const itemText = listItemsText.find((liText) => liText.id === li.theConst);
    li.itemText = itemText ? getListItemText(itemText) : 'N/A';
    return li;
};

const getListItemText = (itemText: ItemText): string =>
    itemText.seriesInfo
        ? `${itemText.seriesInfo.parentTitle.text.value}: ${itemText.text.value}`
        : itemText.text?.value || itemText.text || 'N/A - Invalid Const';

const createHeaders = (): Header<ColumnId>[] => [
    {
        id: 'position',
        label: 'Position'
    },
    {
        id: 'theConst',
        label: 'Const'
    },
    {
        id: 'itemText',
        label: 'Item'
    },
    {
        id: 'originalDescription',
        label: 'Abbreviated Item Description'
    },
    {
        id: 'modified',
        label: 'Last Modified'
    }
];
