import React, { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import {
    CustomRowTableCellProps,
    Data,
    DataTable,
    SelectableOptions
} from '@amzn/imdb-shared-meridian-components/components/DataTable';
import { SelectInput } from '@amzn/imdb-shared-meridian-components/components/InputForm/SelectInput/SelectInput';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Link from '@amzn/meridian/link';
import Pagination from '@amzn/meridian/pagination';
import { TableActionBarProps } from '@amzn/meridian/table-action-bar/table-action-bar';
import Text from '@amzn/meridian/text';

import { ListDetails, PredefinedListDetails } from '../../../../../listoramaAdmin-api/generated-src/api';
import { HEADERS_CONFIG } from './TableHeadersConfig';

export enum ListType {
    LIST = 'LIST',
    PREDEFINED = 'PREDEFINED'
}

export type ColumnId =
    | 'listId'
    | 'name'
    | 'size'
    | 'state'
    | 'publik'
    | 'listClass'
    | 'listType'
    | 'modified'
    | 'created'
    | 'flags';

export type SelectedLists = { [listId: string]: boolean };

interface ListsDataTableProps {
    lists: ListDetails[] | PredefinedListDetails[];
    listType: ListType;
    selectableOptions?: SelectableOptions;
}

export const LIST_FLAGS_DELIMITER = ', ';

const DEFAULT_ITEMS_PER_PAGE = 15;
const ITEMS_PER_PAGE_OPTIONS = [15, 50, 100];

export const ListsDataTable: React.FC<ListsDataTableProps> = (props: ListsDataTableProps) => {
    const { lists, listType, selectableOptions } = props;

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(DEFAULT_ITEMS_PER_PAGE);
    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex = lists.length < itemsPerPage ? lists.length : firstVisibleIndex + itemsPerPage;
    const numberOfPages = Math.ceil(lists.length / itemsPerPage);
    const lastVisibleList = Math.min(lastVisibleIndex, lists.length);

    return lists.length > 0 ? (
        <Column width='100%' data-test-id='listsTable'>
            {listType === ListType.LIST && (
                <Heading level={5}>{`Showing lists ${firstVisibleIndex + 1} to ${lastVisibleList} of ${
                    lists.length
                } total`}</Heading>
            )}
            <DataTable
                data={createTableData(lists.slice(firstVisibleIndex, lastVisibleIndex), listType)}
                selectableOptions={
                    selectableOptions && {
                        idFieldName: selectableOptions.idFieldName,
                        selected: selectableOptions.selected,
                        setSelected: selectableOptions.setSelected
                    }
                }
                actionBarProps={
                    listType === ListType.LIST ? createActionBarProps(itemsPerPage, setItemsPerPage) : undefined
                }
                CustomRowTableCell={CustomRowTableCell}
            />
            {listType === ListType.LIST && (
                <Pagination
                    showSkipArrows={true}
                    numberOfPages={numberOfPages}
                    onChange={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
        </Column>
    ) : (
        <Text type='h300'>User has no lists.</Text>
    );
};

const CustomRowTableCell: React.VFC<CustomRowTableCellProps<ColumnId>> = (props) => {
    const { row, headerId } = props;
    switch (headerId) {
        case 'listId':
            return <LinkTableCellContent data={row.listId} text={row.listId} />;
        case 'listClass':
            return row.listClass !== 'LIST' ? (
                <LinkTableCellContent data={row.listId} text={row.listClass} />
            ) : (
                <DefaultTableCellContent data={row[headerId]} />
            );
        case 'publik':
            return <DefaultTableCellContent data={row.publik ? 'Public' : 'Private'} />;
        default:
            return <DefaultTableCellContent data={row[headerId]} />;
    }
};

const LinkTableCellContent = ({ data, text }) => (
    <Link href={`/listDetails?listId=${data}`} onClick={useNavigate()}>
        {text}
    </Link>
);

const DefaultTableCellContent = ({ data }) => <>{data}</>;

const createTableData = (userLists: ListDetails[] | PredefinedListDetails[], listType: ListType): Data<ColumnId> => {
    return {
        headers: HEADERS_CONFIG[listType],
        rows: userLists.map((list) =>
            listType === ListType.LIST ? { ...list, flags: list.flags.join(LIST_FLAGS_DELIMITER) } : list
        )
    };
};

const createActionBarProps = (
    itemsPerPage: number,
    setItemsPerPage: Dispatch<SetStateAction<number>>
): TableActionBarProps => {
    const options = ITEMS_PER_PAGE_OPTIONS.map((option) => ({ option, value: option, label: option }));
    return {
        children: (
            <SelectInput
                label='Items per page'
                id='items_per_page'
                value={itemsPerPage}
                onChange={setItemsPerPage}
                options={options}
            />
        )
    };
};
