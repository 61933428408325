/**
 * Utility file to store and retrieve CSRF token from the <Meta /> tag. (recommended by OWASP)
 * https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#storing-the-csrf-token-value-in-the-dom
 */

// eslint-disable-next-line
export function storeCSRFToken(token: string) {
    const metaElement = document.querySelector("meta[name='csrf-token']");
    if (metaElement != null) {
        metaElement['content'] = token;
    }
}

export function getCSRFToken(): string | undefined {
    const metaElement = document.querySelector("meta[name='csrf-token']");
    return metaElement ? (metaElement.getAttribute('content') as string) : undefined;
}
