import gql from 'graphql-tag';

import { ListType } from '../../../../listoramaAdmin-api/generated-src';
import {
    TitleItemsTextQuery,
    NameItemsTextQuery,
    VideoItemsTextQuery,
    ImageItemsUrlQuery,
    TitleItemsTextQueryVariables,
    NameItemsTextQueryVariables,
    ImageItemsUrlQueryVariables,
    VideoItemsTextQueryVariables
} from '../../../../types/graphql/GeneratedTypes';

export type ItemsTextQueryVariables =
    | TitleItemsTextQueryVariables
    | NameItemsTextQueryVariables
    | VideoItemsTextQueryVariables
    | ImageItemsUrlQueryVariables;
export type ItemsTextQuery = TitleItemsTextQuery | NameItemsTextQuery | VideoItemsTextQuery | ImageItemsUrlQuery;

export type ListItemsText =
    | TitleItemsTextQuery['consts']
    | NameItemsTextQuery['consts']
    | VideoItemsTextQuery['consts']
    | ImageItemsUrlQuery['consts'];

export interface ItemText {
    id: string;
    text: any;
    seriesInfo?: any;
}

export const getListItemsTextQuery = (listType: ListType) => {
    switch (listType.toUpperCase()) {
        case ListType.Titles:
            return getTitleTextQuery;
        case ListType.People:
            return getNameTextQuery;
        case ListType.Videos:
            return getVideoTextQuery;
        case ListType.Images:
            return getImageUrlQuery;
        default:
            return defaultQuery;
    }
};

const defaultQuery = gql`
    query Default {
        test {
            result
        }
    }
`;

const getTitleTextQuery = gql`
    query TitleItemsText($consts: [ID!]!) {
        consts: titles(ids: $consts) {
            id
            text: titleText {
                value: text
            }
            seriesInfo: series {
                parentTitle: series {
                    text: titleText {
                        value: text
                    }
                }
            }
        }
    }
`;

const getNameTextQuery = gql`
    query NameItemsText($consts: [ID!]!) {
        consts: names(ids: $consts) {
            id
            text: nameText {
                value: text
            }
        }
    }
`;

const getVideoTextQuery = gql`
    query VideoItemsText($consts: [ID!]!) {
        consts: videos(ids: $consts) {
            id
            text: name {
                value
            }
        }
    }
`;

const getImageUrlQuery = gql`
    query ImageItemsURL($consts: [ID!]!) {
        consts: images(ids: $consts) {
            id
            text: url
        }
    }
`;

export default getListItemsTextQuery;
