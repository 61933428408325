import React from 'react';

import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import { useTheme } from '@amzn/meridian/theme';

export const Home: React.VFC = () => {
    const themeTokens = useTheme().tokens;

    return (
        <Box backgroundColor={themeTokens.themePalette200}>
            <Row
                spacingInset='none'
                alignmentHorizontal='start'
                widths={['fill', 'fit']}
                overflowX='hidden'
                minHeight='200px'
            >
                <Column spacingInset='500' minWidth='1024.px'>
                    <Text fontFamily='amazonEmber' type='d100'>
                        IMDb ListORamaAdmin
                    </Text>
                    <Text>
                        Begin by searching for a List ID or Customer ID in the search bar above or use the side bar to
                        navigate.
                    </Text>
                </Column>
            </Row>
        </Box>
    );
};
