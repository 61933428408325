import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSearchContext } from '../../components/Layout';
import { ListDetailsSection } from '../../features/ListDetailsSection/ListDetailsSection';

export const LIST_ID_PARAM = 'listId';

export const ListDetails: React.VFC = () => {
    const { searchInput } = useSearchContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchInput === '') {
            navigate('/');
        }
    }, [searchInput]);

    return <ListDetailsSection searchInput={searchInput} />;
};
