import React from 'react';

import {
    AlertMessage,
    INPUT_TYPE,
    InputFieldProps,
    InputForm
} from '@amzn/imdb-shared-meridian-components/components/InputForm/InputForm';
import closeSmallTokens from '@amzn/meridian-tokens/base/icon/close-small';
import Button from '@amzn/meridian/button';
import Card, { CardHeader } from '@amzn/meridian/card';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Icon from '@amzn/meridian/icon';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Sheet from '@amzn/meridian/sheet';
import Text from '@amzn/meridian/text';

import { ListIdObfuscationToolInput, ListIdObfuscationToolResponseData } from './ListIdObfuscationTool';

interface ListIdObfuscationToolViewProps {
    inputData: ListIdObfuscationToolInput;
    onInputChange: (_: ListIdObfuscationToolInput) => void;
    responseData: ListIdObfuscationToolResponseData;
    isOpen: boolean;
    setIsOpen: (_: boolean) => void;
    onSubmit: () => void;
}

export const ListIdObfuscationToolView: React.VFC<ListIdObfuscationToolViewProps> = (
    props: ListIdObfuscationToolViewProps
) => {
    const { inputData, onInputChange, responseData, isOpen, setIsOpen, onSubmit } = props;
    const onClose = () => setIsOpen(false);

    const setListId = (listId: string) => {
        onInputChange({ ...inputData, listId });
    };

    const createInputFields = (): InputFieldProps[] => [
        {
            type: INPUT_TYPE.TEXT,
            id: 'listId',
            value: inputData.listId,
            onChange: setListId,
            label: 'List ID',
            placeholder: 'Ex: ls123456789'
        }
    ];

    const showLoader = () => {
        return (
            <Column spacingInset='300' alignmentHorizontal='center'>
                <Loader />
                <Heading level={4}>Converting list ID...</Heading>
            </Column>
        );
    };

    const showInputForm = () => {
        return (
            <InputForm inputs={createInputFields()} onSubmit={onSubmit} alertMessage={getAlertMessage(responseData)} />
        );
    };

    const showResults = () => {
        return (
            <Card>
                <CardHeader>
                    <Heading level={3}>Results</Heading>
                </CardHeader>
                <Column>
                    <Text type='b300'>{`Obfuscated: ${responseData.data?.obfuscatedListId}`}</Text>
                    <Text type='b300'>{`Deobfuscated: ${responseData.data?.deobfuscatedListId}`}</Text>
                </Column>
            </Card>
        );
    };

    return (
        <Row>
            <Button type='secondary' data-test-id='idObfuscateToggle' onClick={() => setIsOpen(!isOpen)}>
                List ID Obfuscation Tool
            </Button>
            <Sheet type='overlay' open={isOpen} onClose={onClose}>
                <Column spacing='300'>
                    <Row alignmentHorizontal='end'>
                        <Button onClick={onClose} type='icon'>
                            <Icon tokens={closeSmallTokens}>Close sheet</Icon>
                        </Button>
                    </Row>
                    <Heading level={2}>List ID Obfuscation Tool</Heading>
                    {responseData.fetching ? showLoader() : showInputForm()}
                    {responseData.data && showResults()}
                </Column>
            </Sheet>
        </Row>
    );
};

const getAlertMessage = (responseData: ListIdObfuscationToolResponseData): AlertMessage | undefined => {
    if (responseData.error) {
        return {
            type: 'error',
            message: responseData.error.message
        };
    }
    return undefined;
};
