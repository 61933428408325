import axios from 'axios';

import { getMidwayJwtToken } from '../auth/MidwayJwtToken';
import { getCSRFToken, storeCSRFToken } from '../utils/csrfHelpers';

export const ANTI_CSRF_TOKEN = 'anti-csrftoken-a2z';
export const ANTI_CSRF_TOKEN_REQUEST = 'anti-csrftoken-a2z-request';

const updateResponseHeader = (csrResponseToken: string) => {
    const csrToken = getCSRFToken();
    if (
        !!csrResponseToken &&
        csrResponseToken !== 'null' &&
        csrResponseToken !== 'undefined' &&
        csrResponseToken !== csrToken
    ) {
        storeCSRFToken(csrResponseToken);
    }
};

const handleInvalidCsrfToken = (responseData: any) => {
    if (responseData?.toString().includes('CSRF token missing or invalid')) {
        storeCSRFToken('');
    }
};

export const configureAxios = () => {
    axios.interceptors.request.use(async (config) => {
        const jwtToken = await getMidwayJwtToken();
        config.headers.Authorization = `Bearer ${jwtToken}`;

        const csrfToken = getCSRFToken();
        if (csrfToken) {
            config.headers[ANTI_CSRF_TOKEN] = csrfToken;
        } else {
            config.headers[ANTI_CSRF_TOKEN_REQUEST] = 'true';
        }

        return config;
    });

    // handle response
    axios.interceptors.response.use(
        (response) => {
            // Update the csrf token if it is present in the response headers
            if (ANTI_CSRF_TOKEN in response.headers) {
                const csrfResponseToken = response.headers[ANTI_CSRF_TOKEN];
                updateResponseHeader(csrfResponseToken);
            }
            return response;
        },
        (error) => {
            if (error.response) {
                const errorResponse = error.response;
                handleInvalidCsrfToken(errorResponse.data);
            }
            return Promise.reject(error);
        }
    );
};
