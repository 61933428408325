import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';

import { UserDetailsResponse } from '../../../listoramaAdmin-api/generated-src/api';
import ListORamaAdminApiFactory from '../../../listoramaAdmin-api/ListORamaAdminApiFactory';
import { validateCustomerId } from '../../../utils/validation';
import { ListEditData, ModalData } from '../..//components/ListActionModal/ListActionModal';
import { SelectedLists } from './components/ListsDataTable/ListsDataTable';
import { UserDetailsSectionView } from './UserDetailsSectionView';

export interface UserDetails {
    data?: UserDetailsResponse;
    fetching: boolean;
    fetchingMessage?: string;
    error?: AxiosError;
}

interface UserDetailsSectionProps {
    searchInput: string;
}

const LISTS_PER_LOAD = 250;

export const UserDetailsSection: React.VFC<UserDetailsSectionProps> = (props: UserDetailsSectionProps) => {
    const { searchInput } = props;
    const [userDetails, setUserDetails] = useState<UserDetails>({ fetching: false });
    const [listEditData, setListEditData] = useState<ListEditData>({});
    const [selectedLists, setSelectedLists] = useState<SelectedLists>({});
    const [modalData, setModalData] = useState<ModalData>({ isOpen: false });
    const [canLoadMore, setCanLoadMore] = useState<boolean>(false);

    const createSelectedLists = (data: UserDetailsResponse) => {
        const selectedKeys = Object.assign({}, selectedLists);
        data.user.lists.forEach((data) => {
            if (!(data.listId in selectedKeys)) {
                selectedKeys[data.listId] = false;
            }
        });
        setSelectedLists(selectedKeys);
    };

    useEffect(() => {
        fetchUserDetails(searchInput, false);
    }, [searchInput]);

    const onLoadMore = async () => {
        fetchUserDetails(searchInput, true);
    };

    const fetchUserDetails = async (authorId: string, isLoadingMore: boolean) => {
        try {
            if (!validateCustomerId(authorId)) {
                const error = new Error(`${authorId} is not a valid Customer Id. Please try again.`);
                error.name = 'Bad Request';
                throw AxiosError.from(error);
            }
            setModalData({ isOpen: false });
            if (!isLoadingMore) {
                setUserDetails({
                    fetching: true,
                    fetchingMessage: `Loading user list details for ${authorId}...`,
                    error: undefined
                });
                const response = await ListORamaAdminApiFactory().userDetails.userDetails(authorId, LISTS_PER_LOAD);
                createSelectedLists(response.data);
                setUserDetails((prevState) => ({ ...prevState, data: response.data, fetching: false }));
                //if the user has less lists than we requested, we can assume there are no more lists to load
                setCanLoadMore(response.data.user.lists.length === LISTS_PER_LOAD);
            } else if (userDetails.data !== undefined) {
                //If the data is undefined, we shouldn't be loading more
                setUserDetails((prevState) => ({
                    ...prevState,
                    fetching: true,
                    fetchingMessage: `Loading more user list details for ${authorId}...`,
                    error: undefined
                }));
                const response = await ListORamaAdminApiFactory().userDetails.userDetails(
                    authorId,
                    LISTS_PER_LOAD,
                    userDetails.data.user.lists.length
                );
                userDetails.data.user.lists.push(...response.data.user.lists);
                createSelectedLists(userDetails.data);
                setUserDetails((prevState) => ({ ...prevState, data: userDetails.data, fetching: false }));
                //if we get back less additional lists that we requested, we can assume there are no more lists to load
                setCanLoadMore(response.data.user.lists.length === LISTS_PER_LOAD);
            } else {
                // eslint-disable-next-line no-console
                console.error(
                    'An attempt was made to load more data for an undefined request, please reach out the dev team to report this bug'
                );
            }
        } catch (e) {
            setUserDetails({ error: e as AxiosError, fetching: false });
        }
    };

    const addListFlagBatch = async (data: ListEditData) => {
        try {
            if (data.flag && userDetails.data) {
                setUserDetails({
                    fetching: true,
                    fetchingMessage: `Adding ${data.flag} flag to lists for ${searchInput}...`
                });
                await ListORamaAdminApiFactory().addListFlagBatch.adminBatchAddListFlag({
                    listIds: Object.keys(selectedLists).filter((listId) => selectedLists[listId]),
                    listFlag: data.flag
                });
                setListEditData({ flag: undefined });
                fetchUserDetails(searchInput, false);
            }
        } catch (e) {
            setUserDetails({ error: e as AxiosError, fetching: false });
        }
    };

    const removeListFlagBatch = async (data: ListEditData) => {
        try {
            if (data.flag && userDetails.data) {
                setUserDetails({
                    fetching: true,
                    fetchingMessage: `Removing ${data.flag} flag for lists for ${searchInput}...`
                });
                await ListORamaAdminApiFactory().removeListFlagBatch.adminBatchRemoveListFlag({
                    listIds: Object.keys(selectedLists).filter((listId) => selectedLists[listId]),
                    listFlag: data.flag
                });
                setListEditData({ flag: undefined });
                fetchUserDetails(searchInput, false);
            }
        } catch (e) {
            setUserDetails({ error: e as AxiosError, fetching: false });
        }
    };

    const editListStateBatch = async (data: ListEditData) => {
        try {
            if (data.state && userDetails.data) {
                setUserDetails({
                    fetching: true,
                    fetchingMessage: `Setting state to ${data.state} for lists for ${searchInput}...`
                });
                await ListORamaAdminApiFactory().editListStateBatch.adminEditListStateBatch({
                    customerId: userDetails.data.user.author.authorId,
                    listIds: Object.keys(selectedLists).filter((listId) => selectedLists[listId]),
                    state: data.state
                });
                setListEditData({ state: undefined });
                fetchUserDetails(searchInput, false);
            }
        } catch (e) {
            setUserDetails({ error: e as AxiosError, fetching: false });
        }
    };

    return (
        <UserDetailsSectionView
            searchInput={searchInput}
            userDetails={userDetails}
            listEditData={listEditData}
            setListEditData={setListEditData}
            selectedLists={selectedLists}
            setSelectedLists={setSelectedLists}
            modalData={modalData}
            setModalData={setModalData}
            editListStateBatch={editListStateBatch}
            addListFlagBatch={addListFlagBatch}
            removeListFlagBatch={removeListFlagBatch}
            canLoadMore={canLoadMore}
            onLoadMore={onLoadMore}
        />
    );
};
