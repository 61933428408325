import React, { useEffect, useState } from 'react';
import { Outlet, createSearchParams, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';

import { PageSection } from '@amzn/imdb-shared-meridian-components/components/PageSection';
import { ResetCssStyles } from '@amzn/imdb-shared-meridian-components/components/ResetCssStyles';
import { SearchBar } from '@amzn/imdb-shared-meridian-components/components/SearchBar';
import Alert from '@amzn/meridian/alert';
import AppLayout from '@amzn/meridian/app-layout';
import Select, { SelectOption } from '@amzn/meridian/select';
import Theme from '@amzn/meridian/theme';
import Toaster from '@amzn/meridian/toaster';
import { ToasterToasts } from '@amzn/meridian/toaster/toaster';

import customTheme from '../../assets/theme.json';
import { getAppSetting } from '../../config/AppSettings';
import { validateListConst, validateCustomerId } from '../../utils/validation';
import { ListIdObfuscationTool } from '../features/ListIdObfuscationTool/ListIdObfuscationTool';
import { LIST_ID_PARAM } from '../pages/listDetails/ListDetails';
import { AUTHOR_ID_PARAM } from '../pages/userDetails/UserDetails';
import { LIST_DETAILS, USER_DETAILS } from './AppNavigator/FeatureLinks';
import { Header } from './Header/Header';
import { SideBar } from './SideBar/SideBar';

export type ContextType = {
    searchInput: string;
};

let incrementingId = 0;
const toastVisibilityTimeout = 10000; //10 seconds in milliseconds

export const Layout: React.FC = () => {
    const navigate = useNavigate();
    const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(true);
    const stages = ['Beta', 'Gamma', 'Prod'];
    const currentStage = getAppSetting('stage');

    const [searchParams] = useSearchParams();
    const searchInput = searchParams.get(LIST_ID_PARAM) || searchParams.get(AUTHOR_ID_PARAM) || '';
    const [searchTextValue, setSearchTextValue] = useState<string>(searchInput);
    const [toasts, setToasts] = useState<ToasterToasts[]>([]);
    const onCloseToast = (id) => setToasts(toasts.filter((t) => t.id !== id));
    const onOpenToast = () => setToasts(toasts.concat({ id: `${++incrementingId}`, timeout: toastVisibilityTimeout }));

    const serviceTitle = `IMDbListORamaAdmin`;

    useEffect(() => {
        setSearchTextValue(searchInput);
    }, [searchInput]);

    const onSearchSubmit = (input: string) => {
        const inputString = input.trim();
        if (validateListConst(inputString)) {
            navigate({
                pathname: LIST_DETAILS.link,
                search: createSearchParams({
                    listId: inputString
                }).toString()
            });
        } else if (validateCustomerId(inputString)) {
            navigate({
                pathname: USER_DETAILS.link,
                search: createSearchParams({
                    authorId: inputString
                }).toString()
            });
        } else {
            onOpenToast();
        }
    };

    const HeaderContent = (
        <>
            <ListIdObfuscationTool />
            <SearchBar
                id='headerSearchBar'
                placeholder='Search by list ID or customerid...'
                value={searchTextValue}
                searchButton
                onChange={setSearchTextValue}
                onSubmit={onSearchSubmit}
                containerWidth={400}
            />
        </>
    );

    const invalidSearchToast = (
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
            {(toast) => (
                <Alert toast={true} onClose={toast.onClose}>
                    Not a valid customerId or ListId. Did you enter a userId by mistake?
                </Alert>
            )}
        </Toaster>
    );

    const handleStageSelect = (newStage: string) => {
        if (newStage !== currentStage) {
            location.href = `https://${newStage}.listorama-admin.imdb.amazon.dev`;
        }
    };

    const StageSelector = (
        <Select id='headerStageSelector' placeholder='Alpha' value={currentStage} onChange={handleStageSelect}>
            {stages.map((stage) => (
                <SelectOption
                    key={`${stage}SelectOption`}
                    data-test-id={`${stage}SelectOption`}
                    value={stage}
                    label={stage}
                    aria-disabled={stage === currentStage}
                />
            ))}
        </Select>
    );

    return (
        <Theme palette={customTheme} mode='light'>
            <>
                <ResetCssStyles />
                <AppLayout headerComponent={Header} sidebarComponent={SideBar} backgroundColor={customTheme.palette50}>
                    {invalidSearchToast}
                    <Header
                        isSideBarOpen={isSideBarOpen}
                        setIsSideBarOpen={setIsSideBarOpen}
                        title={serviceTitle}
                        StageSelectComponent={StageSelector}
                        HeaderContentComponent={HeaderContent}
                    />
                    <SideBar isSideBarOpen={isSideBarOpen} />
                    <PageSection>
                        <Outlet context={{ searchInput }} />
                    </PageSection>
                </AppLayout>
            </>
        </Theme>
    );
};

export function useSearchContext() {
    return useOutletContext<ContextType>();
}
