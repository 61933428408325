import axios from 'axios';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
// Promise polyfill
import 'core-js/features/promise';

import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';

import { App } from './app/App';
import { getMidwayJwtToken } from './auth/MidwayJwtToken';
import { initializeAppSettings } from './config/AppSettings';
import { configureAxios } from './config/axiosConfig';
import ListORamaAdminApiFactory from './listoramaAdmin-api/ListORamaAdminApiFactory';

(async () => {
    configureAxios();

    // Make sure Midway is present before rendering
    await getMidwayJwtToken();

    // Initialize application settings
    const appSettings = (await axios('/settings.json')).data;
    initializeAppSettings(appSettings);

    const container = document.getElementById('app');
    const root = createRoot(container!);

    // During delays in startup (e.g. cold starts), show a loading screen
    root.render(
        <Column alignmentHorizontal='center' alignmentVertical='center' minHeight={300}>
            <Loader />
        </Column>
    );

    // Call LORAdmin to determine service health & initialize CSRF tokens
    try {
        await ListORamaAdminApiFactory().ping.ping();
    } catch (e) {
        // Swallow error, to avoid blocking the main page render on error
    }

    root.render(<App />);
})();
