import * as React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Home } from '../../pages/home/Home';
import { ListDetails } from '../../pages/listDetails/ListDetails';
import { UserDetails } from '../../pages/userDetails/UserDetails';
import { Layout } from '../Layout';

export const AppNavigator: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route path='' element={<Home />} />
                    <Route path='listDetails' element={<ListDetails />} />
                    <Route path='userDetails' element={<UserDetails />} />
                </Route>
                <Route path='*' element={<Navigate to='/' replace />} />
            </Routes>
        </BrowserRouter>
    );
};
