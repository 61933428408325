import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import SideMenu, { SideMenuLink, SideMenuTitle } from '@amzn/meridian/side-menu';

import { LinkData } from '../AppNavigator/FeatureLinks';
import { FEATURE_LINKS } from '../AppNavigator/FeatureLinks';

interface Props {
    isSideBarOpen: boolean;
}

export const SideBar: React.FC<Props> = (props) => {
    const { isSideBarOpen } = props;

    const navigate = useNavigate();
    const location = useLocation();
    const handleLinkClick = (linkData: LinkData) => {
        navigate(linkData.link);
    };

    return (
        <SideMenu open={isSideBarOpen}>
            <SideMenuTitle>Features</SideMenuTitle>
            {FEATURE_LINKS.map((linkData) => {
                return (
                    <SideMenuLink
                        key={linkData.id}
                        selected={location.pathname === linkData.link}
                        onClick={() => handleLinkClick(linkData)}
                    >
                        {linkData.text}
                    </SideMenuLink>
                );
            })}
        </SideMenu>
    );
};
