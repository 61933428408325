import { Header } from '@amzn/imdb-shared-meridian-components/components/DataTable';

import { ColumnId, ListType } from './ListsDataTable';

export const HEADERS_CONFIG: Record<ListType, Header<ColumnId>[]> = {
    LIST: [
        {
            id: 'listId',
            label: 'List ID'
        },
        {
            id: 'name',
            label: 'List Name'
        },
        {
            id: 'size',
            label: 'Size'
        },
        {
            id: 'publik',
            label: 'Public'
        },
        {
            id: 'state',
            label: 'State'
        },
        {
            id: 'flags',
            label: 'Flags'
        },
        {
            id: 'listClass',
            label: 'Class'
        },
        {
            id: 'listType',
            label: 'Type'
        },
        {
            id: 'modified',
            label: 'Last Modified'
        },
        {
            id: 'created',
            label: 'Created'
        }
    ],
    PREDEFINED: [
        {
            id: 'listClass',
            label: 'Class'
        },
        {
            id: 'publik',
            label: 'Public'
        },
        {
            id: 'state',
            label: 'State'
        },
        {
            id: 'listType',
            label: 'Type'
        },
        {
            id: 'size',
            label: 'Size'
        },
        {
            id: 'created',
            label: 'Created'
        },
        {
            id: 'modified',
            label: 'Last Modified'
        }
    ]
};
