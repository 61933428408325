import * as React from 'react';

import { DetailsTableCell } from '@amzn/imdb-shared-meridian-components/components/DetailsTable/DetailsTableCell';
import { CustomSingleCellDetailsTableRow } from '@amzn/imdb-shared-meridian-components/components/DetailsTable/DetailsTableRow';
import { NewWindowLink } from '@amzn/imdb-shared-meridian-components/components/link';
import Column from '@amzn/meridian/column';
import { ColumnWidth } from '@amzn/meridian/column/column';
import Table from '@amzn/meridian/table';

import { ListAuthorDetails } from '../../../listoramaAdmin-api/generated-src/api';
import { getUserAdminUrlForAuthor } from '../../../utils/url';

type AuthorDetailsCellId = 'ID' | 'Nickname' | 'Created' | 'Status' | 'Is Shadowbanned?' | 'Is Pro?';

interface AuthorDetailsTableProps {
    author: ListAuthorDetails;
    width?: ColumnWidth;
}

export const AuthorDetailsTable: React.FC<AuthorDetailsTableProps> = (props) => {
    const { author, width } = props;
    const titleDetailsTableRows = createTableRows(author);

    return (
        <Column width={width ? width : '100%'}>
            <Table
                data-test-id='authorDetailsTable'
                showDividers={true}
                spacing='small'
                rowComponents={[CustomSingleCellDetailsTableRow]}
            >
                {titleDetailsTableRows.map((row) => (
                    <CustomSingleCellDetailsTableRow
                        key={row.name}
                        header={row.name}
                        data={row.data}
                        getTableCell={getAuthorDetailsTableCell}
                    />
                ))}
            </Table>
        </Column>
    );
};

interface CustomTableRowProps<T extends string> {
    name: T;
    data: string;
}

const getAuthorDetailsTableCell = (cellId: AuthorDetailsCellId, data: string) => {
    switch (cellId) {
        case 'ID':
            return (
                <DetailsTableCell
                    CustomDataElement={
                        <NewWindowLink url={getUserAdminUrlForAuthor(data)} data-test-id='userAdminLink' text={data} />
                    }
                />
            );
        default:
            return <DetailsTableCell data={data} />;
    }
};

const createTableRows = (author: ListAuthorDetails): CustomTableRowProps<AuthorDetailsCellId>[] => [
    {
        name: 'ID',
        data: author.authorId
    },
    {
        name: 'Nickname',
        data: author.nickname
    },
    {
        name: 'Created',
        data: author.registrationDate
    },
    {
        name: 'Status',
        data: author.status
    },
    {
        name: 'Is Shadowbanned?',
        data: author.isShadowbanned ? 'Yes' : 'No'
    },
    {
        name: 'Is Pro?',
        data: author.isPro ? 'Yes' : 'No'
    }
];
